<template>
  <div class="d-flex flex-column">
    <v-switch
      v-model="readonly"
      label="Read only"
      color="secondary"
    ></v-switch>
    <div class="d-flex flex-row">
      <v-card class="mr-4">
        <v-card-text>
          <v-textarea
            style="width: 800px;"
            label="Value (html)"
            rows="22"
            v-model="content"
          ></v-textarea>
        </v-card-text>
      </v-card>

      <div class="d-flex flex-column">
        <v-card class="mb-4">
          <v-card-text>

            <template-text
              style="width: 800px;"
              :placeholder="$t('t.Content')"
              :tokens="tokens"
              v-model="content"
            />
          </v-card-text>
        </v-card>
        <v-card>
          <div class="d-flex flex-column">
            <label>Result</label>

            <div v-html="content" />

          </div>
        </v-card>

      </div>
    </div>
  </div>

</template>

<script>

export default {
  components: {
    TemplateText: () => import('@/components/template-text')

    // ContentEditor: () => import('@/components/content-editor')
  },
  created () {
  },
  async mounted () {
    const result = await this.$http().get('/templateType/Reminder')
    this.tokens = result.data.tokens.filter(t => !t.isDeprecated)
  },
  data () {
    return {
      tokens: [],
      readonly: false,
      content: `
       
          <blockquote>
       111
          </blockquote>
        `

    }
  },
  methods: {

  }

}
</script>

<style lang="stylus" scoped></style>
